import { useState, useEffect, React } from "react";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

const RotatingText = ({ text, parentRef }) => {


  const minFontSize = 36;
  const maxRadius = 300;
  const viewportWidthCoefficient = 0.9;
  const viewportHeightCoefficient = 1;

  const [fontSize, setFontSize] = useState(48);

  const [viewBox, setViewBox] = useState("0 0 0 0");
  const [pathDef, setPathDef] = useState("");
  const [currentRotation, setCurrentRotation] = useState(0);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    const progress = latest / document.documentElement.clientHeight;
    const rotation = Math.min(progress * 360, 360);
    setCurrentRotation(rotation);
  });


  const updateViewBox = () => {

    if (parentRef) {
      //get dimensions of parent container
      const parentBox = parentRef.current.getBoundingClientRect();

      //find the maximum size of the circle element as constrained by the parent dimensions or maxRadius
      const maxSize = Math.min(
        parentBox.width * viewportWidthCoefficient,
        parentBox.height * viewportHeightCoefficient,
        maxRadius * 2
      );

      //set font size relative to viewport dimensions
      const newFontSize = Math.floor(Math.max(maxSize / 12, minFontSize));
      setFontSize(newFontSize);

      //calculate the circle dimensions/position while allotting for font size
      const diameter = maxSize - newFontSize * 2;
      const startX = parentBox.width / 2 - diameter / 2;
      const startY = getStartY(parentBox.height) - 20;
      // const startY = parentBox.height / 2;
      const newPathDef = `
        M ${startX} ${startY}
        a 25,25 0 1,1 ${diameter},0
        a 25,25 0 1,1 -${diameter},0
        a 25,25 0 1,1 ${diameter},0
        a 25,25 0 1,1 -${diameter},0
      `;
      setPathDef(newPathDef);

      setViewBox([0, 0, document.documentElement.clientWidth, document.documentElement.clientHeight].join(" "));
    }

  }

  const getStartY = (boxHeight) => {
    const clientHeight = document.documentElement.clientHeight;
    const clientWidth = document.documentElement.clientWidth;
    if (clientWidth < clientHeight) {
      return boxHeight / 2 - Math.floor(clientHeight * 0.05);
    }
    return boxHeight / 2;
  }


  //refresh viewbox onmount and when window resizes
  useEffect(() => {
    window.addEventListener("resize", updateViewBox);
    updateViewBox();
    return () => {
      window.removeEventListener("resize", updateViewBox);
    }
  }, []);

  return (

    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} key={"rotation-" + Math.floor(currentRotation)}>

      <title>{text}</title>

      <motion.g
        style={{
          transform: `rotate(${currentRotation}deg)`,
          transformBox: "fill-box",
          transformOrigin: "center center"
        }}
        className="init-rotate"
      >
        <path
          d={pathDef}
          fill="transparent"
          strokeWidth="1"
          stroke="transparent"
          id="path"
          width="100%"
        />

        <text
          className="h1 text-shadow"
          fill="white"
          style={{ fontSize: fontSize + "px", transformOrigin: "center center" }}
        >

          <textPath href="#path" startOffset={"48.5%"} >
            {text}
          </textPath>

        </text>

      </motion.g>

    </svg>
  )
};

export default RotatingText;